<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('交易记录')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="page-main">
            <van-pull-refresh
                    :loosing-text="$t('释放即可刷新')"
                    :pulling-text="$t('下拉即可刷新')"
                    :loading-text="$t('加载中')"
                    v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <div class="trade-panel">
                        <div class="bs-list-card " v-for="(item, index) in list" :key="index" @click="toDetail(item)">
                            <div class="rec-row flex-center">
                                <img :src="item.currency.logo" class="rec-pic">
                                <div class="rec-col">
                                    <div class="rec-tit">{{item.currency.name}}</div>
                                    <div class="rec-time text-gray">{{item.updated_at}}</div>
                                </div>
                            </div>
                            <div class="rec-row flex-center" style="padding-left: 46px;">
                                <div class="rec-col rec-value flex-center" style="min-width: 65px">
                                    <div class="text text-gray">{{$t('收益')}}</div>
                                    <div class="rec-per" :class="item.profit > 0 ? 'text-green':'text-red'">
                                        {{parseFloat(item.profit).toFixed(6)}}
                                    </div>
                                </div>
                                <div class="rec-icon">
                                    <i class="iconfont" :class="item.profit >= 0?'icon-up text-green':'icon-dn text-red'"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                active: '',
                show: false,
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                page: 0,
                size: 20,
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            toDetail(item) {
                this.$router.push({path: '/trade-detail', query: {id: item.id}})
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                this.$axios
                    .get(this.$api_url.order_list + '?page=' + this.page)
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.page = 0;
                this.onLoad();
            },
        },
    }
</script>
<style>
    .bs-list-card{
        margin-bottom: 16px;
        background-color: #1a2331;
        border-radius: 10px;
        font-size: 14px;
        padding:15px 20px;
    }
    .rec-row:first-child{
        margin-bottom: 10px;
    }
    .rec-row .rec-tit{
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;
    }
    .rec-row .rec-time{
        font-size: 12px;
    }
    .rec-row .rec-pic{
        display: block;
        width: 36px;
        height: 36px;
        margin-right: 10px;
    }
    .rec-row .rec-icon{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 26px;
        margin-right: 10px;
    }
    .rec-value {
        opacity: .8;
    }
    .rec-per{
        margin-left: 5px;
    }

</style>

